import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { appConfig } from "../config";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import WootButton from "../components/WootButton";
import { Link } from "react-router-dom";
import WootLogo from "../components/WootLogo";
import axios, { AxiosError } from "axios";

interface DiscordResponse {
  tokenType: string | null;
  accessToken: string | null;
  expiresIn: string | null;
  scope: string | null;
  state: string | null;
}

function VerifyDiscordPage() {
  const { getAccessToken, redirectAuth } = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Redirect if user is not authenticated
  useEffect(() => {
    const checkAuth = async () => {
      const token = await getAccessToken();
      if (!token) {
        redirectAuth(); // Redirect to login
      }
    };
    checkAuth();
  }, [getAccessToken, redirectAuth]);

  // Extract and process the Discord response from the URL hash
  useEffect(() => {
    const hash = location.hash.substring(1);
    const params = new URLSearchParams(hash);

    // If user hits cancel when they link discord, we could get an error in the reply.
    if (params.get("error")) {
      const errorMessage =
        params.get("error_description") || params.get("error") || "Unknown";
      setError(errorMessage);
      setLoading(false);
      return;
    }

    const discordData: DiscordResponse = {
      accessToken: params.get("access_token"),
      expiresIn: params.get("expires_in"),
      tokenType: params.get("token_type"),
      state: params.get("state"),
      scope: params.get("scope"),
    };

    if (!discordData.accessToken) {
      // Redirect
      window.location.href = appConfig.discordLinkRedirect;
    }

    const linkDiscordAccount = async () => {
      try {
        const accessToken = await getAccessToken(); // Await the access token
        if (!accessToken) {
          throw new Error("Failed to retrieve access token");
        }

        await axios.post(
          `${appConfig.aura_url}/account/link-discord`,
          discordData?.accessToken,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setSuccess(true);
      } catch (err) {
        const axiosError = err as AxiosError;
        const errorMessage =
          (axiosError.response?.data as string) || axiosError.message;
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    if (discordData.accessToken) {
      linkDiscordAccount();
    }
  }, [location.hash, getAccessToken]);

  return (
    <Container
      sx={{
        padding: "20px",
        textAlign: "center",
        marginTop: "50px",
        color: "common.white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          width: "200px",
          margin: "0 auto",
        }}
      >
        <WootLogo />
      </Box>
      {loading ? (
        <>
          <CircularProgress
            size={100}
            color="secondary"
            sx={{ marginTop: "20px" }}
          />
          <Typography variant="h6" sx={{ marginTop: "20px" }}>
            Linking...
          </Typography>
        </>
      ) : success ? (
        <>
          <CheckCircleIcon color="secondary" sx={{ fontSize: "50px" }} />
          <Typography variant="h6" sx={{ marginTop: "20px" }}>
            Linked Discord successfully.
          </Typography>
          <Typography sx={{ marginTop: 0, opacity: 0.5 }}>
            You may close this window.
          </Typography>
        </>
      ) : (
        error && (
          <>
            <ErrorIcon sx={{ fontSize: "50px" }} color="error" />
            <Typography color="error" variant="h6" sx={{ marginTop: "20px" }}>
              Failed to connect Discord account
            </Typography>
            <Typography sx={{ marginTop: 0, opacity: 0.5 }}>{error}</Typography>
          </>
        )
      )}
      {!loading && (
        <Link to="/home">
          <WootButton
            sx={{ marginTop: "20px" }}
            wootStyles={{ variant: "secondary" }}
          >
            Go to Homepage
          </WootButton>
        </Link>
      )}
    </Container>
  );
}

export default VerifyDiscordPage;
