import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import VerifyDiscordPage from "./page_components/VerifyDiscordPage";
import LoginPage from "./page_components/LoginPage";
import { AuthProvider } from "./contexts/AuthContext";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "./utils/Theme";
import { AccountProvider } from "./contexts/AccountContext";
import HomePage from "./page_components/HomePage";
import SplashPage from "./page_components/SplashPage";
import ShopPage from "./page_components/ShopPage";
import AccountCreationPage from "./page_components/AccountCreationPage";
import LogoutPage from "./page_components/LogoutPage";
import GameAppPage from "./page_components/GameAppPage";
import NotFoundPage from "./page_components/NotFoundPage";
import BlogPage from "./page_components/BlogPage";
import SettingsPage from "./page_components/SettingsPage";
import UnlinkDiscordPage from "./page_components/UnlinkDiscordPage";

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/splash" />} />
    <Route path="/splash" element={<SplashPage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/logout" element={<LogoutPage />} />
    <Route path="/create-account" element={<AccountCreationPage />} />
    <Route path="/link-discord" element={<VerifyDiscordPage />} />
    <Route path="/unlink-discord" element={<UnlinkDiscordPage />} />

    {/*
      Persistent app pages ensure the game client is not unmounted between page
      hops. This ensures users can visit the store while playing without having
      the iframe app reload (forcing a logout).
    */}
    <Route path="" element={<GameAppPage />}>
      <Route path="/blog/:id" element={<BlogPage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/shop" element={<ShopPage />} />
      {/* The game is always mounted, but becomes visible on this page. */}
      <Route path="/play" element={null} />
    </Route>

    {/* Unmatched routes redirect */}
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export function App() {
  return (
    <BrowserRouter basename="/">
      <AuthProvider>
        <AccountProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRoutes />
          </ThemeProvider>
        </AccountProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
