import React, { createContext, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { appConfig } from "../config";

// Define the AuthContext type
interface AuthContextType {
  getAccessToken: () => Promise<string | null>;
  redirectAuth: () => void;
  setAccessToken: (token: string | null) => void;
}

// Create the context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Helper function to get cookie value
const getCookieValue = (name: string): string | null => {
  const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));
  return cookie ? cookie.split("=")[1] : null;
};

// Function to parse and check if JWT token is expired
const isTokenExpired = (token: string | null): boolean => {
  if (!token) return true;

  const [, payload] = token.split("."); // Extract payload from JWT token
  if (!payload) return true;

  const decoded = JSON.parse(atob(payload));
  const currentTime = Math.floor(Date.now() / 1000);
  return decoded.exp < currentTime; // Check expiration time
};

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    getCookieValue("auth_access_token")
  );
  const navigate = useNavigate();
  const location = useLocation();

  // Function to refresh access token
  const refreshAccessToken = async (): Promise<string | null> => {
    try {
      const response = await axios.post(
        `${appConfig.warden_url}/refresh`,
        {},
        {
          headers: { Accept: "application/json" },
          withCredentials: true, // Ensures cookies are sent and received
        }
      );

      const { access_token } = response.data;
      setAccessToken(access_token); // Update state with new access token
      return access_token;
    } catch (error) {
      console.error("Error during token refresh:", error);
      setAccessToken(null); // Clear token if refresh fails
      return null;
    }
  };

  // Function to get the access token, refreshing if necessary
  const getAccessToken = async (): Promise<string | null> => {
    if (accessToken && !isTokenExpired(accessToken)) {
      return accessToken; // Return valid token from state
    }
    return await refreshAccessToken(); // Refresh if token is missing or expired
  };

  // Redirect the user to the login page if not authenticated
  const redirectAuth = () => {
    const isLoginPage = location.pathname === "/login";

    if (!accessToken && !isLoginPage) {
      const redirectUrl = encodeURIComponent(
        location.pathname + location.search + location.hash
      );
      navigate(`/login?redirect=${redirectUrl}`);
    }
  };

  return (
    <AuthContext.Provider
      value={{ getAccessToken, setAccessToken, redirectAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
