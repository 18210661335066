import React, { useEffect, useState } from "react";
import {
  Paper,
  CircularProgress,
  Box,
  Typography,
  Container,
  Alert,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { useParams } from "react-router-dom";
import { WootBlogPost } from "../components/WootBlog/types";
import remarkGfm from "remark-gfm";
import rehypeSlug from "rehype-slug";
import { formatFullDate } from "../utils/Dates";
import { appConfig } from "../config";

export default function BlogPage() {
  const { id } = useParams<{ id: string }>();
  const [blogPost, setBlogPost] = useState<WootBlogPost | null>(null);
  const [markdown, setMarkdown] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchMarkdown() {
      if (!id) {
        setError("Invalid ID parameter.");
        setLoading(false);
        return;
      }

      try {
        // Fetch the blog post details
        const response = await axios.get(
          `${appConfig.aura_url}/blog/get/${id}`,
          {
            headers: { accept: "application/json" },
          }
        );

        const post: WootBlogPost = response.data;

        if (post.post_url) {
          // Fetch the Markdown content
          const markdownResponse = await axios.get(post.post_url);
          if (markdownResponse.data) {
            setMarkdown(markdownResponse.data);
          } else {
            setError("No markdown content found.");
          }
          setBlogPost(post);
        } else {
          setError("Post URL not found in response.");
        }
      } catch (err) {
        setError(
          err instanceof Error
            ? err.message
            : "Failed to fetch blog post content."
        );
      } finally {
        setLoading(false);
      }
    }

    fetchMarkdown();
  }, [id]);

  return (
    <Paper
      sx={{
        p: 2,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        borderRadius: { xs: 0, lg: "20px" },
        overflow: "auto",
      }}
      square
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginTop: "20px",
          }}
        >
          <CircularProgress size={100} color="secondary" />
        </Box>
      ) : error ? (
        <Container maxWidth="xs">
          <Alert severity="error">{error}</Alert>
        </Container>
      ) : blogPost ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              flexDirection: { xs: "column", sm: "row" }, // Responsive layout
              textAlign: { xs: "center", sm: "left" }, // Center-align on extra-small screens
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              sx={{ mb: { xs: 1, sm: 0 } }}
            >
              {blogPost.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {formatFullDate(blogPost.created_at)}
            </Typography>
          </Box>
          {markdown ? (
            <ReactMarkdown
              children={markdown}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeSlug]}
            />
          ) : (
            <p>No content available.</p>
          )}
        </>
      ) : (
        <Container maxWidth="xs">
          <Alert severity="error">Blog post not found.</Alert>
        </Container>
      )}
    </Paper>
  );
}
