import {
  Container,
  Paper,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  CircularProgress,
  Alert,
  Button,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { appConfig } from "../config";
import Checkout from "../components/Checkout";
import WootOfferList from "../components/WootOffers/WootOfferList";
import { WootOffer } from "../components/WootOffers/types";
import { Link } from "react-router-dom";
import theme from "../utils/Theme";
import axios, { AxiosError } from "axios"; // Import axios

const ShopPage = () => {
  const [offers, setOffers] = useState<WootOffer[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<string | null>(null);
  const [offerType, setOfferType] = useState<"credit" | "membership">("credit");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchOffers = useCallback(async () => {
    setLoading(true);
    setError(null);
    const endpoint =
      offerType === "credit"
        ? "/stripe/get-credit-offers"
        : "/stripe/get-membership-offers";

    try {
      const response = await axios.get(`${appConfig.aura_url}${endpoint}`);
      const { data } = response.data;
      setOffers(data);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) || axiosError.message;
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [offerType]);

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  const sortedOffers = useMemo(() => {
    return offers
      .filter((offer) => offer.active)
      .sort(
        (a, b) => a.default_price.unit_amount - b.default_price.unit_amount
      );
  }, [offers]);

  const handleBuyClick = (offerId: string) => {
    setSelectedOffer(offerId);
  };

  const handleBackClick = () => {
    setSelectedOffer(null);
  };

  const handleOfferTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newOfferType: "credit" | "membership" | null
  ) => {
    if (newOfferType) {
      setOfferType(newOfferType);
      setSelectedOffer(null);
    }
  };

  return (
    <Paper
      sx={{
        p: 2,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        borderRadius: { xs: 0, lg: "20px" },
      }}
      square
    >
      <Box sx={{ display: "flex", width: "100%" }} mb={2}>
        <Typography variant="h4" component="h1">
          Shop Offers
        </Typography>
        {selectedOffer && (
          <Box
            sx={{
              my: "auto",
              ml: "auto",
              mr: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <Button onClick={handleBackClick} aria-label="cancel">
              <ArrowBackIcon sx={{ mr: 1 }} />
              <Typography>Cancel</Typography>
            </Button>
          </Box>
        )}
      </Box>
      <Box
        textAlign={"center"}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {!selectedOffer && (
          <ToggleButtonGroup
            value={offerType}
            exclusive
            onChange={handleOfferTypeChange}
            aria-label="offer type"
            sx={{
              mb: 2,
              marginX: "auto",
              backgroundColor: theme.palette.background.paper,
              width: "100%",
            }}
          >
            <ToggleButton
              value="credit"
              aria-label="credit offers"
              sx={{
                borderRadius: "10px",
                flex: 1,
                fontWeight: "bold",
                fontSize: "1.2em",
                "&.Mui-selected": {
                  backgroundColor: theme.palette.secondary.main, // Selected state background color
                  color: theme.palette.secondary.contrastText, // Selected state text color
                },
                "&.Mui-selected:hover": {
                  backgroundColor: theme.palette.secondary.main, // Selected state background color
                },
              }}
            >
              Credit Offers
            </ToggleButton>
            <ToggleButton
              value="membership"
              aria-label="membership offers"
              sx={{
                borderRadius: "10px",
                flex: 1,
                fontWeight: "bold",
                fontSize: "1.2em",
                "&.Mui-selected": {
                  backgroundColor: theme.palette.secondary.main, // Selected state background color
                  color: theme.palette.secondary.contrastText, // Selected state text color
                },
                "&.Mui-selected:hover": {
                  backgroundColor: theme.palette.secondary.main, // Selected state background color
                },
              }}
            >
              Membership Offers
            </ToggleButton>
          </ToggleButtonGroup>
        )}

        {loading ? (
          <Box sx={{ marginTop: "20px" }}>
            <CircularProgress size={100} color="secondary" />
          </Box>
        ) : error ? (
          <Container maxWidth="xs">
            <Alert severity="error">{error}</Alert>
          </Container>
        ) : selectedOffer ? (
          <Checkout product_id={selectedOffer} />
        ) : (
          <WootOfferList
            offers={sortedOffers}
            onBuyClick={function (product_id) {
              handleBuyClick(product_id);
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        {"We also accept unrestricted donations to support development."}
        <Link to="https://donate.stripe.com/dR63f32VUdQe7mwdQQ" target="_blank">
          <Button sx={{ mx: 1 }}>Donate here</Button>
        </Link>
      </Box>
    </Paper>
  );
};

export default ShopPage;
