import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Typography,
  Box,
  Paper,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WootButton from "../components/WootButton";
import { Link } from "react-router-dom";
import WootForm from "../components/WootForm/WootForm";
import axios, { AxiosError } from "axios";
import { useAuth } from "../contexts/AuthContext";
import { Account, useAccount } from "../contexts/AccountContext";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { appConfig } from "../config";

export default function SettingsPage() {
  const { getAccessToken, redirectAuth } = useAuth();
  const { account } = useAccount();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const isDiscordLinked: string | null = account?.data["discord_id"];

  // Redirect if user is not authenticated
  useEffect(() => {
    const checkAuth = async () => {
      const token = await getAccessToken();
      if (!token) {
        redirectAuth(); // Redirect to login
      }
    };
    checkAuth();
  }, [getAccessToken, redirectAuth]);

  const handleChangePassword = async () => {
    setError("");
    setSuccess(false);

    // Validate input
    if (!password || !newPassword || !confirmNewPassword) {
      setError("All fields are required.");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError("New password and confirmation do not match.");
      return;
    }

    try {
      const response = await axios.post(
        `${appConfig.warden_url}/change-password`,
        {
          new_password: newPassword,
          previous_password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${await getAccessToken()}`,
          },
        }
      );
      if (response.status === 200) {
        setSuccess(true);
        setPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      } else {
        const errorMessage =
          (response.data as string) || "Failed to change password.";
        setError(errorMessage);
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) || axiosError.message;
      setError(errorMessage);
    }
  };

  const handleViewAccountData = (account: Account) => {
    alert(JSON.stringify(account.data, null, 2)); // Display account data in an alert
  };

  return (
    <Paper
      sx={{
        p: 2,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        borderRadius: { xs: 0, lg: "20px" },
      }}
      square
    >
      <Typography variant="h4" component="h1" mb={2}>
        Settings
      </Typography>
      {/* Change Password Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Change Password</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {error && <Alert severity="error">{error}</Alert>}
          {success && (
            <Alert severity="success">Password changed successfully!</Alert>
          )}
          <WootForm
            onSubmit={handleChangePassword}
            submitText="Change Password"
          >
            <TextField
              fullWidth
              required
              type="password"
              label="Current Password"
              variant="outlined"
              margin="normal"
              name="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              fullWidth
              required
              type="password"
              label="New Password"
              variant="outlined"
              margin="normal"
              name="new-password"
              id="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              fullWidth
              required
              type="password"
              label="Confirm New Password"
              variant="outlined"
              margin="normal"
              name="confirm-new-password"
              id="confirm-new-password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </WootForm>
        </AccordionDetails>
      </Accordion>

      {/* External Connections Accordion */}
      <Accordion sx={{ mt: 2 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>External Connections</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Discord Integration</Typography>
            {isDiscordLinked ? (
              <Typography
                color="success.main"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <CheckIcon sx={{ mr: 1 }} /> Linked
              </Typography>
            ) : (
              <Typography
                color="error.main"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <CloseIcon sx={{ mr: 1 }} /> Linked
              </Typography>
            )}
          </Box>
          {isDiscordLinked ? (
            <Link target="_blank" to="/unlink-discord">
              <WootButton
                wootStyles={{ variant: "destructive" }}
                fullWidth
                sx={{ mt: 2 }}
              >
                {"Unlink Discord"}
              </WootButton>
            </Link>
          ) : (
            <Link target="_blank" to="/link-discord">
              <WootButton
                wootStyles={{ variant: "secondary" }}
                fullWidth
                sx={{ mt: 2 }}
              >
                {"Link Discord"}
              </WootButton>
            </Link>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Data & Privacy Accordion */}
      <Accordion sx={{ mt: 2 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Data & Privacy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Typography>
              We are in the process of receiving COPPA and GDPR compliance
              certification from third party auditors. For now, you can find our
              cookie policy and privacy notice at the bottom of our website. You
              may always export your data using the button below.
            </Typography>
            {(account && (
              <WootButton
                fullWidth
                wootStyles={{ variant: "secondary" }}
                sx={{ mt: 2 }}
                onClick={() => {
                  handleViewAccountData(account);
                }}
              >
                View My Data
              </WootButton>
            )) || (
              <WootButton
                disabled
                fullWidth
                wootStyles={{ variant: "secondary" }}
                sx={{ mt: 2 }}
              >
                Loading data...
              </WootButton>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Logout Button */}
      <Link to="/logout">
        <WootButton
          fullWidth
          sx={{
            mt: 4,
            alignSelf: "center",
          }}
          wootStyles={{ variant: "destructive" }}
        >
          Logout
        </WootButton>
      </Link>
    </Paper>
  );
}
