import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios, { AxiosError } from "axios";
import { useAuth } from "./AuthContext";
import { appConfig } from "../config";
import { useLocation, useNavigate } from "react-router-dom";

interface AccountContextType {
  account: Account | null;
  error: string | null;
  loading: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

export interface Account {
  data: any | null;
  isCreated: boolean;
}

const AccountContext = createContext<AccountContextType | undefined>(undefined);

export const AccountProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getAccessToken, setAccessToken } = useAuth();
  const [account, setAccount] = useState<Account | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const logout = async () => {
    setLoading(true);
    try {
      await axios.get(`${appConfig.warden_url}/signout`, {
        withCredentials: true,
      });
      setError(null);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) || axiosError.message;
      setError(errorMessage);
    } finally {
      setAccount(null);
      setAccessToken(null);
      setLoading(false);
    }
  };

  const login = useCallback(async () => {
    const fetchAccountData = async (token: string) => {
      try {
        const response = await axios.get(`${appConfig.aura_url}/account/get`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        setAccount({
          data: response.data,
          isCreated: true,
        });
        setError(null);
      } catch (err) {
        const axiosError = err as AxiosError;
        const status = axiosError.response?.status;

        // 400 = Account does not exist
        if (status === 400) {
          setAccount({
            data: null,
            isCreated: false,
          });
          setError(null);
        } else {
          setError((axiosError.response?.data as string) || axiosError.message);
          setAccount(null);
        }
      }
    };

    setLoading(true);
    try {
      const token = await getAccessToken();
      if (token) {
        await fetchAccountData(token);
      }
    } catch (error) {
      console.error("Failed to log in", error);
      setError("Failed to log in");
    } finally {
      setLoading(false);
    }
  }, [getAccessToken]);

  useEffect(() => {
    const isAccountCreationPage = location.pathname === "/create-account";

    if (!isAccountCreationPage && account?.isCreated === false) {
      const redirectUrl = encodeURIComponent(location.pathname);
      navigate(`/create-account?redirect=${redirectUrl}`);
    }
  }, [account, location.pathname, navigate]);

  return (
    <AccountContext.Provider
      value={{
        account,
        error,
        loading,
        login,
        logout,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccount must be used within an AccountProvider");
  }
  return context;
};
