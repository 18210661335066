import React, { useState } from "react";
import { Box, TextField, Grid, Alert } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { appConfig } from "../../config";
import { useAuth } from "../../contexts/AuthContext";
import WootForm from "../WootForm/WootForm";
import { LoginMenu } from "./WootLogin";

interface WootSigninFormProps {
  emailState: [string, React.Dispatch<React.SetStateAction<string>>];
  menuState: [LoginMenu, React.Dispatch<React.SetStateAction<LoginMenu>>];
}

function WootSigninForm({ emailState, menuState }: WootSigninFormProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAccessToken } = useAuth();
  const [email, setEmail] = emailState;
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [, setMenuState] = menuState;

  const handleLogin = async () => {
    setError(null);
    setSuccess(false);

    try {
      const response = await axios.post(
        `${appConfig.warden_url}/signin`,
        { email, password },
        { withCredentials: true }
      );

      const { access_token } = response.data;

      // Store access token and update context
      localStorage.setItem("auth_access_token", access_token);
      setAccessToken(access_token);

      setSuccess(true);

      // Handle redirect logic
      const redirectUrl = new URLSearchParams(location.search).get("redirect");
      if (redirectUrl) {
        const decodedRedirectUrl = decodeURIComponent(redirectUrl);
        if (
          decodedRedirectUrl.startsWith("http://") ||
          decodedRedirectUrl.startsWith("https://")
        ) {
          window.location.href = decodedRedirectUrl;
        } else {
          navigate(decodedRedirectUrl);
        }
      } else {
        navigate("/home");
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      const status = axiosError.response?.status;

      // 409 CONFLICT if the user has not been verified by email.
      if (status === 409) {
        setMenuState(LoginMenu.VerifyEmail);
      } else {
        const errorMessage =
          (axiosError.response?.data as string) || axiosError.message;
        setError(errorMessage);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">Login successful!</Alert>}
      <WootForm onSubmit={handleLogin} sx={{ mt: 1 }} submitText="Login">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          placeholder="john@smith.com"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: { color: "text.secondary" },
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          placeholder="abc123"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            sx: { color: "text.secondary" },
          }}
        />
      </WootForm>
      <Grid container>
        <Grid item xs>
          <Link
            to="#"
            style={{
              textDecoration: "none",
            }}
            onClick={() => setMenuState(LoginMenu.ForgotPasswordForm)}
          >
            {"Forgot password?"}
          </Link>
        </Grid>
        <Grid item>
          <Link
            to="#"
            style={{
              textDecoration: "none",
            }}
            onClick={() => setMenuState(LoginMenu.Signup)}
          >
            {"No account? Sign Up"}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WootSigninForm;
