import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  CardHeader,
  Alert,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WootLogo from "../components/WootLogo";
import { Link } from "react-router-dom";
import WootForm from "../components/WootForm/WootForm";
import { appConfig } from "../config";
import WootButton from "../components/WootButton";
import { useAccount } from "../contexts/AccountContext";
import { useAuth } from "../contexts/AuthContext";
import axios, { AxiosError } from "axios";

function AccountCreationPage() {
  const { login } = useAccount();
  const { getAccessToken } = useAuth();
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isAvailable, setIsAvailable] = useState(false); // To track if the username is available
  const [checkingAvailability, setCheckingAvailability] = useState(false); // To show loading state while checking

  const handleSignup = async () => {
    setError("");

    if (isAvailable === false) {
      setError("Username is already taken, please choose another.");
      return;
    }

    try {
      const accessToken = await getAccessToken();
      await axios.post(
        `${appConfig.aura_url}/account/create`,
        {
          handle: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Refresh the account state
      await login();
      // Account created!
      setSuccess(true);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) || axiosError.message;
      setError(errorMessage);
    }
  };

  // Function to check username availability
  const checkUsernameAvailability = async (handle: string) => {
    setCheckingAvailability(true);
    setError(""); // Clear any previous errors

    try {
      const response = await axios.get(
        `${appConfig.aura_url}/account/check_available/${handle}`,
        {
          headers: {
            accept: "text/plain",
          },
        }
      );

      setIsAvailable(response.data === "true");
    } catch (err) {
      setError("Error checking username availability.");
    } finally {
      setCheckingAvailability(false);
    }
  };

  // Call availability check when the email (username) changes
  useEffect(() => {
    if (email) {
      const timeoutId = setTimeout(() => {
        checkUsernameAvailability(email);
      }, 500); // Debounce API calls to avoid too many requests
      return () => clearTimeout(timeoutId);
    }
  }, [email]);

  return (
    <Container component="main" maxWidth="xs" sx={{ margin: "50px auto" }}>
      <Card
        sx={{
          borderRadius: "16px",
          boxShadow: 3,
        }}
      >
        <CardHeader
          title="Create account"
          titleTypographyProps={{
            align: "center",
            variant: "h6",
          }}
          sx={{
            backgroundColor: "secondary.main",
            padding: 1,
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          }}
        />
        <CardContent>
          {success ? (
            <Link to="/home" style={{ textDecoration: "none" }}>
              {"← Go Home"}
            </Link>
          ) : (
            <Link to="/logout" style={{ textDecoration: "none" }}>
              {"← Logout"}
            </Link>
          )}
          <Box
            sx={{
              margin: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                maxWidth: "100%",
                overflow: "hidden",
                width: "200px",
              }}
            >
              <WootLogo />
            </Box>
            {success ? (
              <Box sx={{ textAlign: "center", marginTop: 2 }}>
                <h2>Congratulations!</h2>
                <p>Your account has been successfully created.</p>
                <Link to="/play">
                  <WootButton wootStyles={{ variant: "secondary" }}>
                    Play now
                  </WootButton>
                </Link>
              </Box>
            ) : (
              <>
                Welcome to Woot Newts! You first need an account. Please choose
                a username!
                {error && <Alert severity="error">{error}</Alert>}
                <WootForm
                  onSubmit={handleSignup}
                  sx={{ mt: 1 }}
                  submitText="Create account"
                  submitDisabled={!isAvailable || checkingAvailability}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    placeholder="Billybob"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      sx: { color: "text.secondary" },
                      endAdornment: (
                        <InputAdornment position="end">
                          {checkingAvailability ? (
                            <IconButton edge="end" disabled>
                              <MoreHorizIcon />
                            </IconButton>
                          ) : isAvailable ? (
                            <IconButton edge="end" disabled>
                              <CheckIcon color="success" />
                            </IconButton>
                          ) : (
                            <IconButton edge="end" disabled>
                              <CloseIcon color="error" />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </WootForm>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default AccountCreationPage;
