import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { appConfig } from "../config";
import axios, { AxiosError } from "axios"; // Import axios and AxiosError
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import WootButton from "../components/WootButton";
import { Link } from "react-router-dom";
import WootLogo from "../components/WootLogo";

function UnlinkDiscordPage() {
  const { getAccessToken, redirectAuth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [errorText, setErrorText] = useState("Unknown error");

  // Redirect if the user is not authenticated
  useEffect(() => {
    const checkAuth = async () => {
      const token = await getAccessToken();
      if (!token) {
        redirectAuth(); // Redirect to login
      } else {
        // If authenticated, start unlinking process
        unlinkDiscordAccount(token);
      }
    };
    checkAuth();
  }, [getAccessToken, redirectAuth]);

  const unlinkDiscordAccount = async (accessToken: string) => {
    try {
      const response = await axios.delete(
        `${appConfig.aura_url}/account/unlink-discord`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccess(true);
      } else {
        const errorMessage = response.data || "Failed to unlink Discord.";
        setErrorText(errorMessage);
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) ||
        axiosError.message ||
        "Unknown error";
      setErrorText(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      sx={{
        padding: "20px",
        textAlign: "center",
        marginTop: "50px",
        color: "common.white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          width: "200px",
          margin: "0 auto",
        }}
      >
        <WootLogo />
      </Box>

      {loading ? (
        <>
          <CircularProgress
            size={100}
            color="secondary"
            sx={{ marginTop: "20px" }}
          />
          <Typography variant="h6" sx={{ marginTop: "20px" }}>
            Unlinking...
          </Typography>
        </>
      ) : success ? (
        <>
          <CheckCircleIcon color="secondary" sx={{ fontSize: "50px" }} />
          <Typography variant="h6" sx={{ marginTop: "20px" }}>
            Unlinked Discord successfully.
          </Typography>
          <Typography sx={{ marginTop: 0, opacity: 0.5 }}>
            You may close this window.
          </Typography>
        </>
      ) : (
        errorText && (
          <>
            <ErrorIcon sx={{ fontSize: "50px" }} color="error" />
            <Typography color="error" variant="h6" sx={{ marginTop: "20px" }}>
              Failed to unlink Discord account
            </Typography>
            <Typography sx={{ marginTop: 0, opacity: 0.5 }}>
              {errorText}
            </Typography>
          </>
        )
      )}

      {!loading && (
        <Link to="/home">
          <WootButton
            sx={{ marginTop: "20px" }}
            wootStyles={{ variant: "secondary" }}
          >
            Go to Homepage
          </WootButton>
        </Link>
      )}
    </Container>
  );
}

export default UnlinkDiscordPage;
