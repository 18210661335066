import React, { useState } from "react";
import { Box, TextField, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { appConfig } from "../../config";
import WootForm from "../WootForm/WootForm";
import { LoginMenu } from "./WootLogin";

interface WootForgotPasswordFormProps {
  emailState: [string, React.Dispatch<React.SetStateAction<string>>];
  menuState: [LoginMenu, React.Dispatch<React.SetStateAction<LoginMenu>>];
}

function WootForgotPasswordForm({
  emailState,
  menuState,
}: WootForgotPasswordFormProps) {
  const [email, setEmail] = emailState;
  const [error, setError] = useState("");
  const [, setMenuState] = menuState;

  const handleForgotPassword = async () => {
    setError("");

    try {
      const response = await axios.post(
        `${appConfig.warden_url}/forgot-password`,
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      // If successful, move to the password reset menu
      if (response.status === 200) {
        setMenuState(LoginMenu.ResetPassword);
      } else {
        const errorMessage =
          (response.data as string) || "Failed to send password reset.";
        setError(errorMessage);
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) || axiosError.message;
      setError(errorMessage);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      <WootForm
        onSubmit={handleForgotPassword}
        sx={{ mt: 1 }}
        submitText="Send password reset email"
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          placeholder="john@smith.com"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: { color: "text.secondary" },
          }}
        />
      </WootForm>
      <Link
        to="#"
        style={{
          textDecoration: "none",
        }}
        onClick={() => {
          setMenuState(LoginMenu.Signin);
        }}
      >
        {"← Back to Login"}
      </Link>
    </Box>
  );
}

export default WootForgotPasswordForm;
