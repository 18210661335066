import React, { useState } from "react";
import { Box, TextField, Alert, Typography } from "@mui/material";
import axios, { AxiosError } from "axios";
import { appConfig } from "../../config";
import WootForm from "../WootForm/WootForm";
import { LoginMenu } from "./WootLogin";
import WootLoadingLink from "../WootLoadingLink";
import WootButton from "../WootButton";

interface WootResetPasswordFormProps {
  emailState: [string, React.Dispatch<React.SetStateAction<string>>];
  menuState: [LoginMenu, React.Dispatch<React.SetStateAction<LoginMenu>>];
}

function WootResetPasswordForm({
  emailState,
  menuState,
}: WootResetPasswordFormProps) {
  const [email, setEmail] = emailState;
  const [password, setPassword] = useState("");
  const [, setMenuState] = menuState;
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [verifiedSuccess, setVerifiedSuccess] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const [resendLoading, setResendLoading] = useState<boolean>(false);

  const handlePasswordReset = async () => {
    setError("");
    setVerifiedSuccess(false);
    setResendSuccess(false);

    try {
      const response = await axios.post(
        `${appConfig.warden_url}/confirm-forgot-password`,
        {
          confirmation_code: code,
          email,
          password,
        }
      );

      if (response.status === 200) {
        setVerifiedSuccess(true);
      } else {
        setError(response.data || "Failed to reset password.");
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) || axiosError.message;
      setError(errorMessage);
    }
  };

  const handleResendCode = async () => {
    // Reset state
    setError("");
    setResendSuccess(false);

    // Set loading
    setResendLoading(true);

    try {
      const response = await axios.post(
        `${appConfig.warden_url}/forgot-password`,
        {
          email,
        }
      );

      if (response.status === 200) {
        setResendSuccess(true);
      } else {
        setError(response.data || "Failed to resend verification code.");
      }
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorMessage =
        (axiosError.response?.data as string) || axiosError.message;
      setError(errorMessage);
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {(verifiedSuccess && (
        <Alert severity="success">Password reset successful!</Alert>
      )) ||
        (resendSuccess && (
          <Alert severity="success">Code resent to your email.</Alert>
        )) || (
          <Typography color="common.black" sx={{ textAlign: "center" }}>
            Check your email for a password reset code.
          </Typography>
        )}
      {error && <Alert severity="error">{error}</Alert>}
      {!verifiedSuccess && (
        <>
          <WootForm
            onSubmit={handlePasswordReset}
            sx={{ mt: 1 }}
            submitText="Reset password"
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              placeholder="john@smith.com"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{ sx: { color: "text.secondary" } }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="code"
              placeholder="123456"
              label="Password reset code"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              InputProps={{ sx: { color: "text.secondary" } }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder="abc123"
              label="New password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                sx: { color: "text.secondary" },
              }}
            />
          </WootForm>
          <WootLoadingLink
            to="#"
            onClick={handleResendCode}
            loading={resendLoading}
          >
            Resend verification code
          </WootLoadingLink>
        </>
      )}
      {verifiedSuccess && (
        <WootButton
          sx={{
            mt: 1,
          }}
          onClick={() => {
            setMenuState(LoginMenu.Signin);
          }}
        >
          Go to Login
        </WootButton>
      )}
    </Box>
  );
}

export default WootResetPasswordForm;
