import React from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import { Account, useAccount } from "../../contexts/AccountContext";
import { Link } from "react-router-dom";

interface AppBarUserInfoDropdownProps {
  account: Account;
}

function AppBarUserInfoDropdown({ account }: AppBarUserInfoDropdownProps) {
  const { logout } = useAccount();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    logout();
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Typography
        sx={{
          display: { xs: "none", md: "inline" }, // Hides on small devices and shows on medium and larger devices
          fontWeight: "bold",
          mr: 1,
        }}
      >
        {account.isCreated ? account.data["handle"] : "No account"}
      </Typography>
      <Tooltip title="Settings">
        <IconButton
          size="large"
          aria-label="current account"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenUserMenu}
          color="inherit"
        >
          <Person sx={{ width: "1.5em", height: "1.5em" }} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Link to="/settings">
          <MenuItem key="settings" onClick={handleCloseUserMenu}>
            <Typography sx={{ textAlign: "center" }} color="common.black">
              Settings
            </Typography>
          </MenuItem>
        </Link>
        <MenuItem key="logout" onClick={handleLogout}>
          <Typography sx={{ textAlign: "center" }} color="common.black">
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default AppBarUserInfoDropdown;
